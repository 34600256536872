
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import Header from "@/components/Header.vue";
import SearchBar from "@/components/SearchBar.vue";
import { menuController } from "@ionic/vue";
import ApprovalFilterMenu from "@/views/attendance/approval/filter/FilterMenu.vue";
import ApprovalCard from "@/components/ListCard/approvalCard.vue";
import { WorkflowService } from "@/services/attendance/workflow/WorkflowService";
export default defineComponent({
  name: "FilterApproval",
  components: {
    Header,
    SearchBar,
    ApprovalFilterMenu,
    ApprovalCard,
  },
  setup() {
    const api = new WorkflowService();
    const btnSizeRight = reactive({ data: ["38px"] });
    const btnIconRight = reactive({ data: ["iconshaixuan"] });

    const selTb = ref(0);

    const attTabs = reactive({
      title: ["全部", "审批通过", "审批未通过"],
    });

    const segmentChanged = (st: number) => {
      selTb.value = st;
    };

    const approvalList = reactive({
      data: [
        {
          id: "1",
          userName: "高照迁",
          vacationType: "年假",
          startDate: "2021-03-02 09:00",
          endDate: "2021-03-02 16:30",
          days: 5,
          reason: "升级后的反抗集散地和付款时间回复框上",
          approvalState: "",
        },
      ],
    });
   const searchParams = reactive({
      page: 1,
      size: 10,
    });
     let showNoDate = ref(false);
    const resetPage = () => {
      searchParams.page = 1;
      getListRequest((res: any) => {
        approvalList.data = res;
        showNoDate.value = res == null || res.length <= 0;
      });
    };
    const nextPage = (event: any) => {
      searchParams.page++;
      getListRequest((result: any) => {
        if (result) {
          approvalList.data = approvalList.data.concat(result);
        }
        event.target.complete();
      });
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    };
    const doRefresh = (event: any) => {
      resetPage();
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    };
    const getListRequest = (callback: any) => {
      let params = {
        page: searchParams.page,
        size: searchParams.size,
        type:"",
      };
      api
        .getDoneList(params)
        .then((res: any) => {
          callback(res);
        })
        .catch(() => {});
    };
    onMounted(() => {
      resetPage();
    });

    const router = useRouter();

    const goInto = (id: string) => {
      router.push({
        path: "",
        params: {
          id: id,
        },
      });
    };

    const searchHandler = (e: any) => {};

    const openMenuModal = (r: any) => {
      if (r == "0_icon") {
        menuController.enable(true, "ApprovalFilterMenu");
        menuController.open("ApprovalFilterMenu");
      }
    };

    const filterHandler = (r: any) => {
      menuController.close(); 
    };

    return {
      btnSizeRight,
      btnIconRight,
      selTb,
      attTabs,
      segmentChanged,
      approvalList,
      goInto,
      searchHandler,
      openMenuModal,
      filterHandler,
       nextPage,
      doRefresh,
      showNoDate
    };
  },
});


import { menuController, modalController } from "@ionic/vue";
import { defineComponent, reactive, ref } from "vue";
import moment from "moment";
import AvatarList from "@/components/AvatarList.vue";
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";

export default defineComponent({
  name: "ApprovalFilterMenu",
  components: {
    AvatarList,
  },
  emits: ["on-close"],
  setup(props, context) {
    const creatArr = reactive({
      data: new Array(),
    });

    const vacation_type = reactive({
      data: [
        { name: "全部", value: 0 },
        { name: "请假", value: 1 },
        { name: "销假", value: 2 },
        { name: "补假", value: 3 },
        { name: "外出", value: 4 },
        { name: "出差", value: 5 },
        { name: "驻场", value: 6 },
        { name: "异常打卡", value: 7 },
      ],
    });

    const onClose = () => {
      menuController.close();
    };

    let filter_name = ref("");
    let filter_status = ref(0);
    let filter_type = ref(0);
    let filter_date_start = ref("");
    let filter_date_end = ref("");

    const keyNameHandler = (ev: any) => {
      const keyCode = window.event ? ev.keyCode : ev.which;
      if (keyCode === 13) {
        confirm();
      }
    };

    const deleteUser = (index: number) => {
      creatArr.data.splice(index, 1);
    };

    const selectCreator = async () => {
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "other",
            executorList: creatArr.data,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        creatArr.data = data.data;
      }
    };

    const avatarData = (item: any) => {
      var users: any = [];
      let obj = { name: item.name, img: item.photo, id: item.userId };
      users.push(obj);
      return users;
    };

    const checkType = (val: any) => {
      filter_type.value = val.value;
    };

    const resetTime = (val: any) => {
      if (val == 0) {
        filter_date_start.value = "";
      }
      if (val == 1) {
        filter_date_end.value = "";
      }
    };

    const reset = () => {
      filter_name.value = "";
      creatArr.data = new Array();
      filter_type.value = 0;
      filter_date_start.value = "";
      filter_date_end.value = "";
    };

    const confirm = () => {
      if (filter_date_start.value != "") {
        filter_date_start.value = moment(filter_date_start.value).format(
          "YYYY-MM-DD"
        );
      }
      if (filter_date_end.value != "") {
        filter_date_end.value = moment(filter_date_end.value).format(
          "YYYY-MM-DD"
        );
      }
      const params = {
        name: filter_name.value,
        users: creatArr.data,
        type: filter_type.value,
        dateStart: filter_date_start.value,
        dateEnd: filter_date_end.value,
      };
      context.emit("on-close", params);
    };

    return {
      creatArr,
      vacation_type,
      filter_name,
      filter_status,
      filter_type,
      filter_date_start,
      filter_date_end,
      onClose,
      deleteUser,
      selectCreator,
      avatarData,
      checkType,
      resetTime,
      keyNameHandler,
      reset,
      confirm,
    };
  },
});
